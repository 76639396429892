import React from "react"

import {
  SEO,
  Layout,
  Projects,
  Header,
  ContactForm,
  Services,
} from "components"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Header />
    <Projects />
    <Services />
    <ContactForm />
  </Layout>
)

export default IndexPage
